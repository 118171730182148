import type { EventProperties } from '@segment/analytics-next';
import { useCallback } from 'react';
import { isBrowser } from '../../browser';

export const isSegmentLoaded = () =>
  isBrowser() && 'segmentAnalytics' in window && typeof window.segmentAnalytics === 'object';

export const useSendSegmentEvent = <T>() =>
  useCallback(async (action: string, params: T) => {
    if (!isSegmentLoaded()) return Promise.reject('Segment is not loaded');
    await window.segmentAnalytics.track(action, params as EventProperties);
    return Promise.resolve(params);
  }, []);
