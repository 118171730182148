import type { Cart, Customer, Order, Product } from 'storefront-client';
import type { Auction } from 'storefront-client';
import {
  type EventProcessor,
  gaAddCartItemsProcessor,
  gaAddShippingInfoProcessor,
  gaBeginCheckoutProcessor,
  gaBidCompletedProcessor,
  gaRemoveCartItemsProcessor,
  gaViewCartProcessor,
  gaViewItemProcessor,
  segmentBeginCheckoutProcessor,
  segmentBidCompletedProcessor,
  segmentBidOrBuyNowActionProcessor,
  segmentLoginProcessor,
  segmentLogoutProcessor,
  segmentModalClosedProcessor,
  segmentModalOpenedProcessor,
  segmentNavigationProcessor,
  segmentPostalCodeEnteredProcessor,
  segmentProductPhotoClickProcessor,
  segmentSearchProcessor,
  segmentShareProcessor,
  segmentSignUpProcessor,
  segmentSubscribeProcessor,
} from './processors';
import type { ModalNames, NavigationEventType, ShareContentMethods, SignUpMethods } from './types';

export type AnalyticsConsumer = 'ga' | 'segment';
export type DispatchEvent<
  EventName extends keyof EventInputData = keyof EventInputData,
  ProcessedData = EventInputData[EventName],
> = (action: EventName, data: ProcessedData) => Promise<ProcessedData>;
export type AnalyticsEventName = keyof EventInputData;

export type AnalyticsEventRegistry = {
  [EventName in AnalyticsEventName]: {
    // biome-ignore lint/suspicious/noExplicitAny: TBD
    [Consumer in AnalyticsConsumer]?: EventProcessor<EventName, any> | boolean;
  };
};
export type UseSendEvent<K extends keyof EventInputData> = (eventName: K) => (data: EventInputData[K]) => void;
export type Entity = Order | Cart;
export interface EventInputData {
  sign_up: { method: SignUpMethods; email: string };
  login: { method: string; customer?: Customer };
  logout: { customer?: Customer };
  search: { search_term: string };
  share: { method: ShareContentMethods } & ShareData;
  view_item: { product: Product; currencyCode: string };
  view_cart: { cart: Cart };
  add_to_cart: { updatedCart: Cart; previousCart?: Cart };
  remove_from_cart: { updatedCart: Cart; previousCart?: Cart };
  subscribe: { email: string; customer?: Omit<Partial<Customer>, 'password_hash'> };
  begin_checkout: { cart: Cart };
  bid_completed: { entity: Entity };
  add_shipping_info: { cart: Cart };
  modal_opened: { name: ModalNames };
  modal_closed: { name: ModalNames };
  postal_code_entered: { postalCode: string; supported: boolean };
  navigation: { type: NavigationEventType; destination: string };
  product_photo_click: { auction: Auction; image_index: number };
  bid_or_buy_now_action: { auction: Auction; description: string };
}

export const events: AnalyticsEventRegistry = {
  sign_up: { ga: true, segment: segmentSignUpProcessor },
  login: { ga: true, segment: segmentLoginProcessor },
  logout: { segment: segmentLogoutProcessor },
  search: { ga: true, segment: segmentSearchProcessor },
  share: { ga: true, segment: segmentShareProcessor },
  view_item: { ga: gaViewItemProcessor },
  view_cart: { ga: gaViewCartProcessor },
  add_to_cart: { ga: gaAddCartItemsProcessor },
  remove_from_cart: { ga: gaRemoveCartItemsProcessor },
  subscribe: { segment: segmentSubscribeProcessor },
  begin_checkout: { ga: gaBeginCheckoutProcessor, segment: segmentBeginCheckoutProcessor },
  bid_completed: { ga: gaBidCompletedProcessor, segment: segmentBidCompletedProcessor },
  add_shipping_info: { ga: gaAddShippingInfoProcessor },
  modal_opened: { ga: true, segment: segmentModalOpenedProcessor },
  modal_closed: { ga: true, segment: segmentModalClosedProcessor },
  postal_code_entered: { ga: true, segment: segmentPostalCodeEnteredProcessor },
  navigation: { segment: segmentNavigationProcessor },
  product_photo_click: { segment: segmentProductPhotoClickProcessor },
  bid_or_buy_now_action: { segment: segmentBidOrBuyNowActionProcessor },
};
